<template>
  <div class="container-fluid">
    <div class="row row--grid">
      <div class="col-12 col-xl-10 offset-xl-1">
        <!-- breadcrumb -->
        <ul class="breadcrumb" v-if='selectedModule'>
          <li class="breadcrumb__item"><router-link to="/">홈</router-link></li>
          <li class="breadcrumb__item breadcrumb__item--active">{{selectedModule.title}}</li>
        </ul>
        <!-- end breadcrumb -->

        <div class="article" v-if='selectedModule'>
          <!-- article content -->
          <div class="article__content">
            <img v-if="$route.params.name !== ''" :src="'/img/preview/' + $route.params.name + '.jpg'" :alt="$route.params.title">
            <img v-else src="/img/posts/1.jpg">

            <div class="article__meta">
              <a class="article__category" :class="{'article__category_hidden': !selectedModule.webcam}" aria-label="웹캠이 필요합니다." data-balloon-pos="right"><img class='article__category__image' src="/icon/webcam.svg"/></a>
              <span class="article__date">
                <img class='article__date__image' src="/icon/bookmark.svg"/> {{selectedModule.bookChapter}}
                <img class='article__date__image' src="/icon/page.svg"/> {{selectedModule.bookPage}}
              </span>
            </div>

            <h3>{{selectedModule.title}}</h3>
            <p>{{selectedModule.description}}</p>
            <!-- end article content -->

            <!-- share -->
            <a class="share d-flex align-items-center my-3" style='flex-wrap: inherit; cursor: default' v-if="selectedModule.howTo && selectedModule.howTo !== ''">
              <span class="share__link m-0" style='min-width: 58px'>방법</span>
              &nbsp;&nbsp;&nbsp;
              <span style='color: #c0c0c0'>{{selectedModule.howTo}}</span>
            </a>
            <a class="share d-flex align-items-center my-3" :href="selectedModule.source" target="_blank" style='overflow: hidden; white-space: nowrap; text-overflow: ellipsis; flex-wrap: nowrap'>
              <span class="share__link m-0">출처</span>
              &nbsp;&nbsp;&nbsp;
              <span style='color: #c0c0c0'>{{selectedModule.source}}</span>
            </a>
            <a class="share d-flex align-items-center my-3" @click='showLicense(selectedModule.license)' style='overflow: hidden; white-space: nowrap; text-overflow: ellipsis; flex-wrap: nowrap; cursor: pointer'>
              <span class="share__link m-0">라이선스</span>
              &nbsp;&nbsp;&nbsp;
              <span style='color: #c0c0c0'>{{selectedModule.license}}</span>
            </a>
            <!-- end share -->

            <!-- comments -->
            <div class="comments mt-3 p-0">
              <div class="comments__form mt-3 p-0" style='border: none'>
                <div class='row'>
                  <div class='col-12 col-sm-6'>
                    <a type="button" class="sign__btn" :href="selectedModule.href" target="_blank">실행하기</a>
                  </div>
                  <div class='col-12 col-sm-6 mt-4 m-sm-0'>
                    <a @click='$router.go(-1)' class="hero__btn">뒤로 가기</a>
                  </div>
                </div>
              </div>
            </div>
            <!-- end comments -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'underscore'

export default {
  name: "Article",
  data: function() {
    return {
      modules: store.state.modules,
      selectedModule: null
    };
  },
  created() {
    let name = this.$route.params.name;
    this.selectedModule = _.findWhere(this.modules, {name: name});

    $('html, body').animate({
      scrollTop: 0
    }, 250);
  },
  computed: {
  },
  methods: {
    showLicense(name){
      let url;
      switch (name) {
        case 'Apache License 2.0':
          url = 'https://www.olis.or.kr/license/Detailselect.do?lId=1002';
          break;
        case 'MIT':
          url = 'https://www.olis.or.kr/license/Detailselect.do?lId=1006';
          break;
        case 'Creative Commons Attribution 4.0':
          url = 'https://creativecommons.org/licenses/by/4.0/deed.ko';
          break;
      }

      if(url){
        window.open(url, '_blank');
      }
    }
  },
}
</script>
